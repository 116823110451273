import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { AuthService } from './auth.service';
import { Observable } from 'rxjs';

@Injectable()
export class ApiService {

  constructor(private httpService: HttpService, private authService: AuthService) { }

  verifyAccount(token: string): Observable<any> {
    const url = `${this.authService.getApiUrl()}/users/verifyAccount`;
    const payload = { 
      token: token
    };
    return this.httpService.doPost(url, payload, null);
  }
}
